interface Config {
	env: 'development' | 'production' | 'local' | any,
	baseUrl: string,
	vnpayRedirectDomain: string,
	googleKey: string,
	grapUrl: string,
  termOfUseUrl: { vi: string; en: string; kr: string };
}

const config: Config = {
	env: 'production',
	baseUrl: 'https://api.swift247.vn', // no ending slash!
	vnpayRedirectDomain: 'pay.vnpay.vn',
	googleKey: 'AIzaSyB28k55rvVd3MUokqBdH-N3ct2ACTD7B54',
	grapUrl: 'https://api-us-east-1.graphcms.com',
  termOfUseUrl: {
    vi: 'https://swift247.vn/dieu-khoan-su-dung',
    en: 'https://kr.swift247.vn/en/term-of-use.html',
    kr: 'https://kr.swift247.vn/en/term-of-use.html',
  }
}

export default config;
