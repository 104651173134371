import axios from 'axios';
import { DTOAddressKorea, DTOAddressKoreaRaw, DTOLocation, DTOLocationRaw } from 'Services/v1/locations/dto';
import { PAGESIZE } from 'Utilities/constants';
import { CamelSnakeMapper } from '../../data-mappers';
import httpClient from '../../http-client';
import { DTOResponseMetaData, DTOResponseMetaDataRaw, DTOServiceResponse } from '../../interfaces';
import RootServiceV1 from '../root';


class LocationServiceV1 extends RootServiceV1 {
  constructor() {
    super();
  }

  async getLocations(id: string | number): Promise<DTOServiceResponse<DTOLocation>> {
    try {
      const response = await httpClient().get('v2/get_location', {
        params: {
          locId: id
        }
      });

      return {
        statusCode: 200,
        data: CamelSnakeMapper.toCamelCase<DTOLocationRaw, DTOLocation>(response.data)
      };
    } catch (err) {
      const parsedError = this._errorParser(err);
      return {
        statusCode: parsedError.statusCode,
        error: parsedError,
      };
    }
  }

  async getLocationsByAddress(address: string) {
    try {
      const a = axios.create({
        baseURL: "https://maps.googleapis.com/maps/api/",
        headers: {
          'Content-Type': 'application/json'
        },
      });
      const response = await a.get('geocode/json', {
        params: { address, key: 'AIzaSyB28k55rvVd3MUokqBdH-N3ct2ACTD7B54' }
      });
      return {
        statusCode: 200,
        data: CamelSnakeMapper.toCamelCase<DTOLocationRaw, DTOLocation>(response.data)
      };
    } catch (err) {
      const parsedError = this._errorParser(err);
      return {
        statusCode: parsedError.statusCode,
        error: parsedError,
      };
    }
  }

  async getAddressKorea(
		id: string | number,
    pageIndex: number,
    txtSearch: string
	): Promise<DTOServiceResponse<DTOAddressKorea[]>> {
		try {
			const response = await httpClient().get('/v1/order/address-korea' , {
        params: {
          loc_id: id,
          page: pageIndex,
          page_size: PAGESIZE,
          search: txtSearch
        }
      });
			const data = response.data.data;
      const meta = response.data.meta;
			return {
				statusCode: 200,
				data: data.map(CamelSnakeMapper.toCamelCase<DTOAddressKoreaRaw, DTOAddressKorea>),
        meta: CamelSnakeMapper.toCamelCase<DTOResponseMetaDataRaw, DTOResponseMetaData>(meta)
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}

}

export default (new LocationServiceV1()) as LocationServiceV1;
